<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="450">
      <v-card>
        <v-card-title class="headline text-truncate" t-data="headline">
          <h4>
            <span :class="`${headlineColor}--text`">{{ title }}</span>
            <v-icon v-if="icon" :color="headlineColor">{{ icon }}</v-icon>
          </h4>
        </v-card-title>
        <v-card-text t-data="card-text">
          <div class="content-detail" v-html="subTitle"></div></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="alert-cancel"
            class="cancel-button button-bold"
            t-data="cancel-btn"
            color="info600Color"
            text
            @click="close"
          >
            {{ buttonCancel ? buttonCancel : 'Cancel' }}</v-btn
          >
          <v-btn
            v-if="buttonText"
            id="alert-confirm"
            class="confirm-button button-bold"
            t-data="confirm-btn"
            :color="confirmBtnColor"
            text
            @click="comfirmPopup"
            >{{ buttonText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'AlertConfirm',
  props: {
    show: {type: Boolean},
    close: {type: Function},
    confirm: {type: Function},
    icon: {
      type: String,
      required: false,
      default: 'information'
    },
    title: {
      type: String,
      required: false,
      default: 'Confirmation'
    },
    subTitle: {type: String},
    headlineColor: {
      type: String,
      required: false,
      default: 'orange'
    },
    confirmBtnColor: {
      type: String,
      required: false,
      default: 'danger600Color'
    },
    buttonText: {type: String},
    buttonCancel: {type: String}
  },
  methods: {
    closePopup() {
      this.close()
    },
    comfirmPopup() {
      this.confirm()
    }
  }
}
</script>

<style scoped>
.v-card__title.headline {
  line-height: 45.02px;
  padding: 16px 24px 8px 24px;
}
</style>
