<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          <span class="orange--text">Confirmation</span>
          <v-icon color="orange">information</v-icon>
        </v-card-title>
        <v-card-text>
          This content will be changed to
          <span
            v-bind:style="{color:status === 'Active' ? 'green' : 'red', fontSize: '16px'}"
          >{{status}}</span>
          <br />Are you sure you want to change?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="comfirmPopup">Change</v-btn>
          <v-btn color="black darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'AlertConfirm',
  props: {
    show: {type: Boolean},
    close: {type: Function},
    confirm: {type: Function},
    status: {type: String}
  },
  methods: {
    closePopup() {
      this.close()
    },
    comfirmPopup() {
      this.confirm()
    }
  }
}
</script>

<style scoped>
</style>