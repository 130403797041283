<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="95%">
      <div style="background: grey">
        <v-row no-gutters style="padding: 10px">
          <v-col cols="12" sm="11">
            <p t-data="page-headline">Preview</p>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn t-data="close-btn" @click="close">close</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-card>
        <v-card-text>
          <v-row no-gutters>
            <v-col class="container" cols="5" sm="5">
              <div t-data="image-th">
                <v-img
                  v-bind:src="imageThai"
                  lazy-src="{imageThai}"
                  class="grey lighten-2"
                  max-width="100%"
                  contain
                  max-height="10%"
                ></v-img>
              </div>
              <div t-data="title-th" style="margin-top: 20px">
                <h2>{{titleThai}}</h2>
              </div>
              <div t-data="subtitle-th" style="margin-top: 20px" v-show="object.full_price">
                <div v-show="object.is_promotion == 1">
                  <p class="underline">{{convertFullPrice}} บาท</p>
                </div>
                <div>
                  <h3 class="text_price">{{convertPrice}} บาท</h3>
                </div>
                <div style="margin-top: 20px">
                  <p v-html="descriptionThai"></p>
                </div>
              </div>
              <div t-data="description-th" style="margin-top: 20px" v-show="!object.full_price">
                <p v-html="descriptionThai"></p>
              </div>
            </v-col>
            <v-col class="container" cols="1" sm="1"></v-col>
            <v-col class="container" cols="6" sm="5">
              <div t-data="image-en">
                <v-img
                  v-bind:src="imageEng"
                  lazy-src="{imageEng}"
                  class="grey lighten-2"
                  max-width="100%"
                  contain
                  max-height="auto"
                ></v-img>
              </div>
              <div style="margin-top: 20px">
                <h2 t-data="title-en">{{titleEng}}</h2>
              </div>
              <div t-data="subtitle-en" style="margin-top: 20px" v-show="object.full_price">
                <div v-show="object.is_promotion == 1">
                  <p class="underline">{{convertFullPrice}} baht</p>
                </div>
                <div>
                  <h3 class="text_price">{{convertPrice}} baht</h3>
                </div>
                <div style="margin-top: 20px">
                  <p v-html="descriptionEng"></p>
                </div>
              </div>
              <div t-data="description-en" style="margin-top: 20px" v-show="!object.full_price">
                <p v-html="descriptionEng"></p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {isUrl, converMathCeil} from '../helper/helper'

export default {
  name: 'Preview',
  props: {
    closePreivew: {type: Function},
    show: {type: Boolean},
    object: {type: Object}
  },
  computed: {
    convertPrice: function() {
      return converMathCeil(this.object.price)
    },
    convertFullPrice: function() {
      return converMathCeil(this.object.full_price)
    },
    titleThai: function() {
      if (this.object.content) {
        return this.object.content[0].code === 'th'
          ? this.object.content[0].title
          : this.object.content[1].title
      } else {
        return ''
      }
    },
    titleEng: function() {
      if (this.object.content) {
        return this.object.content[0].code === 'en'
          ? this.object.content[0].title
          : this.object.content[1].title
      } else {
        return ''
      }
    },
    descriptionThai: function() {
      if (this.object.content) {
        return this.object.content[0].code === 'th'
          ? this.object.content[0].description
          : this.object.content[1].description
      } else {
        return ''
      }
    },
    descriptionEng: function() {
      if (this.object.content) {
        return this.object.content[0].code === 'en'
          ? this.object.content[0].description
          : this.object.content[1].description
      } else {
        return ''
      }
    },
    imageThai: function() {
      if (this.object.content) {
        let image =
          this.object.content[0].code === 'th'
            ? `${this.object.content[0].thumbnail}`
            : `${this.object.content[1].thumbnail}`
        if (isUrl(image)) {
          return `${image}?${new Date().getTime()}`
        } else {
          return image
        }
      } else {
        return ''
      }
    },
    imageEng: function() {
      if (this.object.content) {
        let image =
          this.object.content[0].code === 'en'
            ? `${this.object.content[0].thumbnail}`
            : `${this.object.content[1].thumbnail}`
        if (isUrl(image)) {
          return `${image}?${new Date().getTime()}`
        } else {
          return image
        }
      }
      return ''
    }
  },
  mounted() {},
  methods: {
    close() {
      this.closePreivew()
    }
  }
}
</script>

<style scoped>
.container {
  border-color: gray;
  padding-top: 20px;
}
.underline {
  text-decoration: line-through;
}
.text_price {
  color: deepskyblue;
}
</style>
