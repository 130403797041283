<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <h1 class="headline">Coupons</h1>
      </v-col>
    </v-row>
    <v-preview
      :show="isPreview"
      :closePreivew="closePreview"
      :object="previewObject"
    />
    <v-diglog-update
      :show="isUpdate"
      :status="action.status"
      :close="closePopup"
      :confirm="confirmUpdate"
    />
    <v-diglog-confirm
      :show="isDelete"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :buttonText="buttonConfirmText"
      :confirm="confirmDelete"
    />

    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar v-model="isCreateFail" :content="snackbarFail" type="error"></snack-bar>

    <v-row>
      <v-col>
        <v-btn
          v-if="hasPermCreate()"
          large
          color="primary"
          :to="{name: 'coupon-create'}"
          ><v-icon left>mdi-plus</v-icon>Add Coupon</v-btn
        >
      </v-col>
    </v-row>

    <!-- Search and Filter -->
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="filters.status"
          @input="selectStatus"
          :items="filters.dropdownStatus"
          label="Status"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="3"></v-col>
      <v-col>
        <v-text-field
          @input="textSearch"
          v-model="searchPhase"
          class="seachBox"
          append-icon="search"
          label="Search by title"
          single-line
          hide-details
          @click:clear="clearSearch()"
          clearable
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Bulk action -->
    <v-row v-if="hasPermEdit()">
      <v-col cols="3">
        <v-select
          v-model="action.status"
          :items="action.dropdownActions"
          label="Force Update Status"
          @input="setUpdateStatus"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-btn
          large
          color="primary"
          :disabled="isBulkUpdate"
          @click="updateStatusID"
          >Update</v-btn
        >
      </v-col>
    </v-row>

    <!-- Data table -->
    <v-card>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          v-model="selected"
          :loading="isLoading"
          :options.sync="options"
          :headers="table.headers"
          :items="couponsList"
          :items-per-page="table.pageSize"
          :server-items-length="totalCoupons"
          :footer-props="{'items-per-page-options': table.itemPerPages}"
          @input="selectId"
          show-select
          class="elevation-1"
          item-key="id"
          fixed-header
          style="width: 100%"
          :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
        <template v-slot:item.content="{item}" style="width: 130px">
          <v-list-item-content
            class="d-inline-block text-truncate"
            style="width: 130px"
          >
            <v-list-item-title>
              {{
                item.contents[0].code === 'th'
                  ? item.contents[0].name
                  : item.contents[1].name
              }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                item.contents[0].code === 'en'
                  ? item.contents[0].name
                  : item.contents[1].name
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-slot:item.admin="{item}">
          {{ !!item.admin ? item.admin.name : '' }}
        </template>
        <template v-slot:item.status="{item}">
          <v-chip :color="statusColor(item.status)" dark>
            {{ item.status == 0 ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>
        <template v-slot:item.price="{item}">
          {{ `${item.price}${item.discount_type === 0 ? ' THB' : '%'}` }}
        </template>
        <template v-slot:item.quantity="{item}">
          {{ `${item.quantity_using}/${item.quantity}` }}
        </template>
        <template v-slot:item.update_date="{item}">
          {{ dateFmt(item.update_date) }}
        </template>
        <template v-slot:item.is_publish="{item}">
          <v-chip :color="statusColorPublish(item.is_publish)" dark>
            {{ item.is_publish ? 'Publish' : 'Unpublish' }}
          </v-chip>
        </template>
        <template v-slot:item.is_promotion="{item}">
          {{ item.is_promotion }}
          <!-- <v-icon color="green" v-show="item.is_promotion">check_circle</v-icon> -->
        </template>
        <template v-slot:item.action="{item}">
          <div style="width: 130px">
            <v-icon small class="px-2" @click="showPreview(item.id)"
              >search</v-icon
            >
            <v-icon
              v-if="hasPermEditByOrgs(item.orgs)"
              small
              class="px-2"
              @click="rowClick(item)"
              >edit</v-icon
            >
            <v-icon
              v-else
              class="px-2 action-icon transparent-icon"
            ></v-icon>
            <v-icon
              v-if="hasPermDeleteByOrgs(item.orgs)"
              small
              class="px-2"
              @click="showDeletePopup(item.id)"
              >delete</v-icon
            >
            <v-icon
              v-else
              class="px-2 action-icon transparent-icon"
            ></v-icon>
          </div>
        </template>
      </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {convertDate, hasPermision, hasPermissionsByOrgs, searchHandler, snackbarSuccessContent, snackbarFailContent} from '../../helper/helper'
import Popup from './updateStatusPopup'
import Confirm from '../../components/AlertConfirm'
import Preview from '../../components/Preview'
import SnackBar from '@/components/SnackBar'
import {actionEnum} from '@/constants/constants'

export default {
  components: {
    'v-diglog-update': Popup,
    'v-diglog-confirm': Confirm,
    'v-preview': Preview,
    SnackBar
  },
  computed: {
    previewObject() {
      let previewObject = this.couponObject
      previewObject.content = this.couponObject.contents
      return previewObject
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('coupon', [
      'isDelete',
      'isPreview',
      'couponsList',
      'isLoading',
      'totalCoupons',
      'updateIdList',
      'couponObject'
    ]),
    isBulkUpdate() {
      return this.updateIdList.length == 0 || this.action.status === ''
    }
  },

  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    hasPermCreate() {
      return hasPermision('CanAddCoupon')
    },
    hasPermEdit() {
      return hasPermision('CanEditCoupon')
    },
    hasPermEditByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanEditCoupon', codenames)
    },
    hasPermDeleteByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanDeleteCoupon', codenames)
    },
    clearSearch() {
      this.setTextSearch('')
      this.setPage(1)
      this.fetch()
    },
    showPreview(id) {
      this.setcouponPreview(id)
    },
    closePreview() {
      this.setShowPreview(false)
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()

      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup(id) {
      this.setDeleteId(id)
      this.setDeletePopup(true)
    },
    selectId(event) {
      this.selected = event
      this.setSelectedId(event)
    },
    statusColor(status) {
      return this.colorsLookup[status]
    },
    statusColorPublish(status) {
      if (status) {
        return 'green'
      }
      return 'red'
    },
    setUpdateStatus(event) {
      this.action.status = event
    },
    updateStatusID() {
      if (this.selected.length > 0) this.isUpdate = true
    },
    confirmUpdate() {
      this.isUpdate = false
      this.update(this.action.status)
      this.selected = []
    },
    closePopup() {
      this.isUpdate = false
    },
    dateFmt: date => convertDate(date),
    selectStatus() {
      this.setFilterStatus(this.filters.status)
      this.fetch()
    },
    tickPromotion() {
      this.setOnlyPromotion(this.filters.onlyPromotion)
      this.fetch()
    },
    textSearch() {
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.searchPhase.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    rowClick(pkg) {
      this.$router.push({name: 'coupon-edit', params: {id: pkg.id}})
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    ...mapActions('coupon', [
      'fetch',
      'setPage',
      'setPageSize',
      'setFilterStatus',
      'setOnlyPromotion',
      'setTextSearch',
      'setFieldSort',
      'setSort',
      'setSelectedId',
      'update',
      'deletePkg',
      'setDeletePopup',
      'setDeleteId',
      'setShowPreview',
      'setcouponPreview',
      'resetList'
    ])
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  data() {
    return {
      pageName: 'coupon',
      heightOptions: {
        header: 488,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: '',
      buttonConfirmText: 'Delete',
      subTitle:
        'This content will be deleted in both Thai and English.\nAre you sure you want to delete this content?',
      deleteId: 0,
      searchPhase: '',
      filters: {
        status: 'All',
        onlyPromotion: false,
        dropdownStatus: [
          {text: 'All', value: ''},
          {text: 'Active', value: 0},
          {text: 'Inactive', value: 1}
        ]
      },
      isUpdate: false,
      selected: [],
      options: {},
      action: {
        status: '',
        dropdownActions: ['Active', 'Inactive']
      },
      colorsLookup: {
        0: 'green',
        1: 'red',
        2: 'yellow accent-4'
      },
      table: {
        page: 1,
        pageSize: 25,
        itemPerPages: [25, 50, 100],
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: true,
            value: 'id'
          },
          {
            text: 'Title',
            align: 'left',
            value: 'content',
            sortable: true
          },
          {
            text: 'Updated By',
            align: 'center',
            value: 'admin',
            sortable: true
          },
          {
            text: 'Value',
            align: 'center',
            value: 'price',
            sortable: false
          },
          {
            text: 'Quota',
            align: 'center',
            value: 'quantity',
            sortable: false
          },
          {
            text: 'Updated',
            align: 'center',
            value: 'update_date',
            sortable: true
          },
          {
            text: 'Publish',
            align: 'center',
            value: 'is_publish',
            sortable: false
          },
          {
            text: 'Status',
            align: 'center',
            value: 'status',
            sortable: false
          },
          {
            text: 'Actions',
            align: 'center',
            value: 'action',
            sortable: false
          }
        ]
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          to: '/'
        },
        {
          text: 'Coupon',
          disabled: true,
          link: true,
          to: '/'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
    filters: {
      handler() {
        this.options.page = 1
      },
      deep: true
    }
  }
}
</script>
