<template>
  <v-snackbar
    v-model="data"
    width="500"
    :color="bgColor"
    top
    centered
  >
    <div class="d-flex flex-row align-center mt-0">
      <v-img
        t-data="status-image"
        :src="`/images/snackbar-${type}.png`"
        aspect-ratio="1"
        snackbar-image
        class="mt-0 mr-2 snackbar-image"
      ></v-img>
      <p t-data="status-content" :class="className">
        {{ content }}
      </p>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackBar',
  props: {
    type: {
      type: String,
      default: 'success',
      required: false
    },
    status: {
      type: Boolean,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  model: {
    prop: 'status',
    event: 'change',
  },
  computed: {
    data: {
      get: function() {
        return this.status
      },
      set: function(value) {
        this.$emit('change', value)
      }
    },
    bgColor() {
      if (this.type === 'error') {
        return 'danger200Color'
      } else {
        return 'success200Color'
      }
    },
    className() {
      const className = 'snackbar-message sub-body-regular mb-0'
      if (this.type === 'error') return `${className} snackbar-message-error`
      return className
    }
  },
}
</script>
